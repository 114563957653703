<template>
  <div class="ContentContainer JustifyCenter">
    <div class="LoginContainer">
      <div class="LoginBox">
        <div class="LoginBoxScaffold">
          <!-- Greeting -->
          <div class="TextTitle">{{new_user? GREETING_NEW_USER : GREETING_EXISTING_USER}}</div>
          <!-- Error Message -->
          <div class="LoginBoxRow" v-if="errorMessage">
            <div class="ErrorContainer">{{ errorMessage }}</div>
          </div>
          <!-- Invitation Code -->
          <div class="LoginBoxRow VerticalSpace" v-if="new_user">
            <div class="ChildCardRow">  
              <input class="ThemedInput LoginInputHeight" type="text" id="invitation_code" v-model="invitation_code"
              :placeHolder="PLACE_HOLDER_INVITATION_CODE">
              <label class="InputLabel" for="invitation_code">{{LABEL_INVITATION_CODE.toUpperCase()}}</label>
            </div>
          </div>
          <!-- Sign In With LinkedIn Button -->
          <div class="SignInButtonContainer" @click="signInWithLinkedIn">
            <img :src="linkedin_logo" class="AuthLogo"/>
            <button class="SignInButton">
              <div class="AuthText">{{ PROMPT_LINKEDIN }}</div>
            </button>
          </div>
          <!-- Sign In With Google Button -->
          <div class="SignInButtonContainer" @click="signInWithGoogle">
            <img :src="google_logo" class="AuthLogo"/>
            <button class="SignInButton">
              <div class="AuthText">{{ PROMPT_GOOGLE }}</div>
            </button>
          </div>
          <!-- Sign-in/ Sign-up Message -->
          <div class="LoginBoxRow">
            <div class="TextParagraph"> {{ new_user? PROMPT_SIGN_IN_NEW_USER : PROMPT_SIGN_IN_EXISTING_USER}}</div>
            <button class="TertiaryButton" @click="toggleUserMessage"> 
              {{ new_user? CTA_SIGN_IN_NEW_USER : CTA_SIGN_IN_EXISTING_USER}}
            </button>
          </div>
        </div>
        <a class="LogoHolder" href="https://uppl.ai" target="_blank">
          <div class="LogoGroup">
              <icon icon-name="upplai-logo"></icon>
          </div>
        </a>
      </div>
      <div class="TermsHolder">
        <div class="HorizontalGroup">
          <a class="TextDescription" href="https://uppl.ai/terms/" target="_blank">Terms of Service</a>
          <div class="TextDescription">|</div>
          <a class="TextDescription" href="https://uppl.ai/privacy/" target="_blank">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import ToastMessage from './ui_controls/ToastMessage.vue'
import LinkedinLogo from '../../assets/images/LinkedIn-Logo.png';
import GoogleLogo from '../../assets/images/Google-Logo.svg';
import Icon from './ui_controls/Icon.vue'; 

export default {
  components: {
    ToastMessage,
    Icon,
  },
  data() {
    return {
      new_user: true, //whether it's a new user experience
      frontend_error: null, //if there's any frontend error
      invitation_code: null, // user entered invitation code
      // Sign in buttons
      linkedin_logo: LinkedinLogo,
      google_logo:GoogleLogo,
      // --- Content ---
      GREETING_NEW_USER: 'Create an account',
      GREETING_EXISTING_USER: 'Welcome back',
      PROMPT_LINKEDIN: 'Continue with LinkedIn',
      PROMPT_GOOGLE: 'Continue with Google',
      PROMPT_SIGN_IN_NEW_USER: 'Already have an account?',
      PROMPT_SIGN_IN_EXISTING_USER: 'Don\'t have an account?',
      LABEL_INVITATION_CODE: 'Invitation Code (Optional)',
      PLACE_HOLDER_INVITATION_CODE: 'Enter your invitation code if you have one',
      CTA_SIGN_IN_NEW_USER: 'Sign In',
      CTA_SIGN_IN_EXISTING_USER: 'Sign Up',
      ERROR_GATED_ACCESS: 'You are no longer signed in. Please sign in to continue',
      ERROR_LOGIN_FAILURE: 'There was an error signing in. Please try signing in again',
      ERROR_OAUTH_REJECTED: 'Access to LinkedIn is required to sign in. Please try again',
      ERROR_TERMS_NOT_ACCEPTED: 'Please accept our terms of service in order to continue',
      ERROR_NO_CODE: 'Upplai is currently invitation only. Please enter you invitation code to proceed',
      ERROR_INVALID_CODE: 'The code you provided is not a valid invitation code',
      ERROR_HEAVY_TRAFFIC: 'We are currently experiencing heavy traffic. Please try again in a few minutes',
    };
  },
  created() {
    console.log('Login screen avigated from page: ' + this.previousRoute);
    // 1. Check if this is a new user or existing user based on the URL param
    const existingUser = this.$route.query.existing_user;
    this.new_user = existingUser === undefined || existingUser === 'false'; //the existing_user param should be present or true for an existing user
    // 2. Check error codes in URL
    const loginError = this.$route.query.error;
    if (loginError ==='access_denied') { //if the error param = access_denied
      this.frontend_error=this.ERROR_OAUTH_REJECTED;
    } else if (loginError ==='invalid_code'){
      this.frontend_error=this.ERROR_INVALID_CODE;
    } else if (loginError ==='missing_code'){
      this.frontend_error=this.ERROR_NO_CODE;
    } else if (loginError ==='heavy_traffic'){
      this.frontend_error=this.ERROR_HEAVY_TRAFFIC;
    }
    //3. Check for invitation codes in URL params
    const code = this.$route.query.invitation;
    if ( this.new_user && code !== undefined) {
      this.invitation_code= code.toUpperCase();
    }
  },
  computed: {
    ...mapGetters(['previousRoute']), //get previous page name
    errorMessage() {
      if (this.frontend_error) {
        return this.frontend_error;
      } else {
        switch (this.previousRoute) {
        case 'Login': //If the user navigated from 'Login' screen, there was most likely a server error signing the user in
          return this.ERROR_LOGIN_FAILURE;
        case 'Home': //If the user was navigated from 'Home' screen, they most likely weren't signed in when trying to access the application
          return this.ERROR_GATED_ACCESS;
        case 'Terms': //If the user was navigated from 'Terms' screen, they most likely didn't accept the latest terms
          return this.ERROR_TERMS_NOT_ACCEPTED;
        default:
          return null;
        }
      }
    },
  },
  methods: {
    // Check if invitation code is provided for a new user and update error message accordingly
    validateCode(){
      console.log('Validate Code');
      this.frontend_error=null;
      if (!this.new_user) {
        console.log('Existing user - no validation necessary');
        return true; // No validation necessary for existing users
      }
      // Check if the invitation code is null or an empty string
      if (!this.invitation_code || this.invitation_code.trim() === '') {
        this.frontend_error = this.ERROR_NO_CODE;
        console.log('Missing invitation code');
        return false;
      }
      // Otherwise, invitation code is valid
      console.log('New user: invitation code provided');
      return true;
    },
    //Sign in with LinkedIn OAuth
    async signInWithLinkedIn() {
      // Invitation code is no longer required. The following lines are no longer necessary
      // const code_validated = this.validateCode(); //validate if invitation code is present
      // if (!code_validated) return; //do nothing if code isn't validated
      try {
        // Step 1: Make API call to backend to get the URL for initiating the LinkedIn OAuth flow
        let auth_url = '/api/v1/auth/linkedin/url';
        if (this.invitation_code) {
          // Append the invitation_code as a query parameter to the URL
          auth_url += `?invitation_code=${encodeURIComponent(this.invitation_code)}`;
        }
        const response = await axios.get(auth_url); //Make the API call
        const url = response.data.url; //get the authentication URL from the response
        window.location.href = url; //Open the authentication URL on the same tab
      } catch (error) {
        console.error("Error authenticating with LinkedIn:" + error);
        this.navigateTo('Login'); //Redirect user to login screen -> this will show an authentication failure error
      }
    },
    //Sign in with Google OAuth2
    async signInWithGoogle() {
      // Invitation code is no longer required. The following lines are no longer necessary
      // const code_validated = this.validateCode(); //validate if invitation code is present
      // if (!code_validated) return; //do nothing if code isn't validated
      try {
        // Step 1: Make API call to backend to get the URL for initiating the Google OAuth flow
        let auth_url = '/api/v1/auth/google_oauth2/url';
        if (this.invitation_code) {
          // Append the invitation_code as a query parameter to the URL
          auth_url += `?invitation_code=${encodeURIComponent(this.invitation_code)}`;
        }
        const response = await axios.get(auth_url); //Make the API call
        const url = response.data.url; //get the authentication URL from the response
        window.location.href = url; //Open the authentication URL on the same tab
      } catch (error) {
        console.error("Error authenticating with Google:" + error);
        this.navigateTo('Login'); //Redirect user to login screen -> this will show an authentication failure error
      }
    },
    // Navigate user to destination
    navigateTo(destination){
      this.$store.commit('setPreviousRoute', this.$route.name); //save the previous route name in Vue store
      this.$router.push({ name: destination });
    },
    // Switch from new user to existing user Login screen
    toggleUserMessage(){
      this.new_user = !this.new_user;
    },
  },
};
</script>

<style scoped>
.JustifyCenter {
  justify-content: center;
}
.LoginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.LoginBox {
  width: 25rem;
  border: var(--border-thickness-selected) solid transparent;
  border-radius: var(--card-border-radius);
  background-image: linear-gradient(white, white), linear-gradient(to right, var(--primary-500), var(--secondary-500));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: var(--shadow-elevation-four);
  position: relative;
}

.LoginBoxScaffold {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25rem;
  gap: 1rem;
}

.LoginBoxRow {
  display: flex;
  justify-content: center;
  width: 20rem; /* Make sure that it is the same width as SignInButtonContainer*/
  gap: 0.25rem;
}
.LogoHolder {
  width: 25rem;
  height: 4rem;
  position: absolute;
  top: -8rem;
  left: -0.125rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.TermsHolder{
  margin: 1rem;
}
a.TextDescription {
  text-decoration: none;
}
.LogoGroup {
  position: relative;
  height: 3rem;
}
.SignInButtonContainer{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.625rem 1.25rem;
  height: 3.25rem;
  width: 20rem; /* Make sure that it is the same width as LoginBoxRow*/
  gap: 0.625rem;
  border-radius: var(--button-border-radius);
  border: var(--border-thickness-selected) solid var(--color-outline-normal);
  cursor: pointer;
  color: var(--color-text-description);
}

.SignInButtonContainer:hover,
.SignInButtonContainer:focus-within{
  border-color: var(--color-input-normal);
  color: var(--color-input-normal);
}

.SignInButtonContainer:focus-within {
  outline: var(--border-thickness-selected) solid var(--color-background-primary);
}
.SignInButtonContainer:active{
  border-color: var(--color-input-selected);
  color: var(--color-input-selected);
  background-color: var(--color-background-primary);
}
.AuthLogo{
  width: calc(1.75rem * 635/540);  /* Adjust for LinkedIn Logo dimensions*/
  height: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AuthText{
  width: 100%;
  color: inherit;
  font-size: var(--size-large);
  display: flex;
  justify-content: center;
  align-items: center;
}

.SignInButton {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent ;
  color:inherit;
}
.ErrorContainer {
  background-color: var(--danger-50);
  padding: 0.625rem 1.25rem;
  border-radius: var(--card-border-radius);
  width: 20rem; 
  text-align: center;
  color: var(--color-text-paragraph);
  font-size: var(--size-normal);
}
.VerticalSpace {
  margin-top: 1rem;
}
.LoginInputHeight{
  line-height: 1.75rem;
}
</style>