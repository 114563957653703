<template>
   <svg ref="chart" width="100%" height="100%" viewBox="0 0 100 100">
    <defs>
      <linearGradient :id="componentId" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" :stop-color="color" stop-opacity="0.5" />
        <stop offset="40%" :stop-color="color" stop-opacity="1" />
      </linearGradient>
    </defs>
    <circle cx="50" cy="50" r="40" stroke="var(--gray-200)" stroke-width="10" fill="none"></circle>
    <path :d="arcPath" :stroke="`url(#${componentId})`" stroke-width="10" fill="none" stroke-linecap="round" :stroke-dasharray="dashArray"></path>
    <text x="50" y="50" text-anchor="middle" dominant-baseline="middle" fill="var(--color-text-description)">{{ percentage }}</text>
  </svg>
</template>
  
  <script>
  export default {
    props: {
      percentage: {
        type: Number,
        required: true,
        validator: value => value >= 0 && value <= 100
      },
      color: {
        type: String,
        required: true,
        default: 'var(--primary-800)'
      },
      componentId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        // required for animating the chart from 0 to final value defined in %
        circumference: 0,
        dashArray: '0 0'
      };
    },
    mounted() {
      this.calculateCircumference();
      window.addEventListener('resize', this.calculateCircumference);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.calculateCircumference);
    },
    computed: {
      arcPath() {
        const radius = 40;
        const angle = (this.percentage / 100) * 360;
        
        if (this.percentage === 100) {// Handle the case when percentage is 100%
            return `M 50 10 A ${radius} ${radius} 0 1 1 49.99 10`; //this should be a full cricle
        } else {
            const startX = 50 + Math.sin((angle * Math.PI) / 180) * radius;
            const startY = 50 - Math.cos((angle * Math.PI) / 180) * radius;
            const largeArcFlag = angle > 180 ? 1 : 0;
            return `M 50 10 A ${radius} ${radius} 0 ${largeArcFlag} 1 ${startX} ${startY}`;
        }
      },
    },
    watch: {
      percentage: { // any change in the percentage property should trigger the animation
        handler: 'animateChart',
        immediate: true // This property triggers the animation on initial render
      }, 
    },
    methods: {
      calculateCircumference() {
        const radius = this.$refs.chart.getBoundingClientRect().width / 2;
        console.log('Radius='+radius);
        this.circumference = 2 * Math.PI * radius;
      },
      animateChart() {
        const dashArray = (this.percentage / 100) * this.circumference;
        const dashOffset = this.circumference - dashArray;

        this.dashArray = `0 ${this.circumference}`;
        setTimeout(() => {
          this.dashArray = `${dashArray} ${dashOffset}`;
        }, 300); //animation duration in milli seconds
      }
    },
  }
  </script>
  
  <style scoped>
  text {
    font-size: var(--size-normal);
    font-weight: var(--weight-normal);
  }
  </style>